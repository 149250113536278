import React from "react";
import bannerImg from "../assets/images/bannerImg.png"
import bgVideo from "../assets/videos/bgVideo.webm";
import x from "../assets/icons/x.png";
import telegram from "../assets/icons/telegram.png"
import idFEW22lT from "../assets/icons/idFEW22lT.png"
import CoinM from "../assets/icons/CoinM.png"
import GCoin from "../assets/icons/GCoin.png"
import PumpFun from "../assets/icons/PumpFun.png"
import { WistiaPlayer } from "@wistia/wistia-player-react";
import {BrowserView, MobileView} from "react-device-detect";
import socials from '../config/socials';

const Main = () => {
  return (
    <div className="relative w-full h-screen overflow-x-auto">
      <BrowserView>
        <video
          src={bgVideo}
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 min-w-full min-h-full object-cover"
          type="video/mp4"
        />
      </BrowserView>

      <MobileView>
        <WistiaPlayer
          mediaId="rs4osact7f"
          controlsVisibleOnLoad={false}
          playBarControl={false}
          fullscreenButton={false}
          settingsControl={false}
          volumeControl={false}
          playPauseNotifier={false}
          smallPlayButton={false}
          seo={false}
          playButton={false}
          autoplay={true}
          silentAutoplay={true}
          endVideoBehavior="loop"
          muted={true}
          qualityMin="1440"
          qualityMax="1440"
        />
        {/* overlay to ignore clicks */}
        <div className="absolute inset-0 bg-transparent" />
      </MobileView>


      {/* Image and Icons Container */}
      <div className="absolute top-2 right-4 sm:top-4 sm:right-12 md:top-4 md:right-4 lg:top-12 lg:right-24 xl:top-8 xl:right-12 2xl:top-36 2xl:right-32 flex flex-col items-center">
        <img
          src={bannerImg}
          alt="PIGISH"
          className="w-44 sm:w-[22rem] md:w-96 lg:w-[30rem] xl:w-[45rem]"
        />
        <div className="flex mt-1 space-x-1 sm:space-x-4">
          <a href={socials.twitter} target="_blank" rel="noopener noreferrer">
            <img
              src={x}
              alt="X Icon"
              className="w-10 sm:w-10 md:w-16 transition-transform duration-300 ease-in-out transform hover:scale-125"
            />
          </a>
          <a href={socials.telegram} target="_blank" rel="noopener noreferrer">
            <img
              src={telegram}
              alt="Telegram Icon"
              className="w-10 sm:w-10 md:w-16 transition-transform duration-300 ease-in-out transform hover:scale-125"
            />
          </a>
          <a href={socials.dex} target="_blank" rel="noopener noreferrer">
            <img
              src={idFEW22lT}
              alt="idFEW22lT Icon"
              className="w-10 sm:w-10 md:w-16 transition-transform duration-300 ease-in-out transform hover:scale-125"
            />
          </a>
          <a href={socials.gecko} target="_blank" rel="noopener noreferrer">
            <img
              src={GCoin}
              alt="GCoin Icon"
              className="w-10 sm:w-10 md:w-16 transition-transform duration-300 ease-in-out transform hover:scale-125"
            />
          </a>
          <a href={socials.coin_market_cap} target="_blank" rel="noopener noreferrer">
            <img
              src={CoinM}
              alt="CoinM Icon"
              className="w-10 sm:w-10 md:w-16 transition-transform duration-300 ease-in-out transform hover:scale-125"
            />
          </a>
          <a href={socials.pumpfun} target="_blank" rel="noopener noreferrer">
            <img
              src={PumpFun}
              alt="PumpFun Icon"
              className="w-10 sm:w-10 md:w-16 transition-transform duration-300 ease-in-out transform hover:scale-125"
            />
          </a>
        </div>
      </div>


    </div>
  )
}

export default Main;