const socials = {
  twitter: 'https://x.com/PigishCTO',
  telegram: 'https://t.me/PigishCto',
  dex: 'https://www.dextools.io/app/en/solana/pair-explorer/2z61ETgPFq5Lb8ADTDFMwbauAYpws5Gw5nDaSvuVpump?t=1729006534110',
  gecko: 'https://www.coingecko.com/',
  coin_market_cap: 'https://coinmarketcap.com/',
  pumpfun: 'https://pump.fun/2z61ETgPFq5Lb8ADTDFMwbauAYpws5Gw5nDaSvuVpump'
};

export default socials;
